import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useGetOnCallWorkplaces() {
  const { onCallAreas } = useCbhFlag(CbhFeatureFlag.PRICING_ON_CALL_SHIFTS, {
    defaultValue: {
      offersDistanceInMiles: 50,
      noPlacementBonusPayHours: 2,
      onCallAreas: [],
    },
  });

  return new Set(
    onCallAreas.reduce((accumulator, area) => {
      if (area.parentWorkplaceId) {
        accumulator.add(area.parentWorkplaceId);
      }

      return accumulator;
    }, new Set<string>())
  );
}
