import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";

import { ListLoadingState } from "../../components/ListLoadingState";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { convertShiftToSlotId } from "../../Shift/Open/useGetShiftsSlotV2";
import { useOpenShiftListDataContext } from "../../Shift/Open/useOpenShiftListDataContext";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { ShiftVirtualizedList } from "../../Shift/Open/VirtualizedList";
import { PriorityShiftsEmptyState } from "./EmptyState";

interface PriorityShiftsListProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

export function PriorityShiftsList(props: PriorityShiftsListProps) {
  const { scrollRef } = props;

  const { listRef, virtualShiftListItems } = useVirtualShiftListContext();

  const isListEmpty = virtualShiftListItems.length === 0;

  const {
    onClickOpenShift,
    workplacesMap,
    shiftsMissingRequirementsMap,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
    shiftsSlotsMap,
    friendsMap,
    recentColleaguesMap,
    handleRangeChanged,
  } = useOpenShiftListDataContext();

  if (isOpenShiftsError) {
    return <Text align="center">Error loading priority shifts</Text>;
  }

  if (isOpenShiftsLoading) {
    return (
      <ListLoadingState
        texts={[
          "Finding you the best-paying shifts",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isOpenShiftsSuccess && isListEmpty) {
    return <PriorityShiftsEmptyState />;
  }

  return (
    <ShiftVirtualizedList
      ref={listRef}
      scrollRef={scrollRef}
      items={virtualShiftListItems}
      renderItem={(shift) => {
        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, showMissingDocumentsCount, isInstantReview } =
          shiftsMissingRequirementsMap.get(shift.id) ?? {};

        return (
          <OpenShiftCard
            key={shift.id}
            workers={
              shiftsSlotsMap
                .get(
                  convertShiftToSlotId({
                    start: shift.attributes.start,
                    end: shift.attributes.end,
                    facilityId: workplace.id,
                  })
                )
                ?.shiftAssignments.map(({ worker }) => worker) ?? []
            }
            shift={shift}
            workplace={workplace}
            missingDocuments={missingDocuments ?? []}
            showMissingDocumentsCount={showMissingDocumentsCount ?? false}
            isInstantReview={isInstantReview ?? false}
            friendsMap={friendsMap}
            recentColleaguesMap={recentColleaguesMap}
            onClick={({ offer }) => {
              onClickOpenShift({ shift, workplace, offer });
            }}
          />
        );
      }}
      onRangeChanged={handleRangeChanged}
    />
  );
}
